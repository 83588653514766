<template>
  <!-- Tabular -->
  <div>
    <b-overlay
      variant="white"
      :show="showLoad"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-table
        ref="refList"
        class="position-relative"
        :items="fetchRecords"
        :fields="fields"
        striped
        responsive
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell()="data">
          <i>{{data.value.length > 20 ? data.value.substring(0,20) + '...' : data.value }}</i>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-dow justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Lists.Showing') }} {{ dataMeta.from }} {{ $t('Lists.To') }} {{ dataMeta.to }} {{ $t('Lists.Of') }} {{ dataMeta.of }} {{ $t('Lists.Entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-end"
          >
            <label>{{ $t('Lists.Show') }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('Lists.Entries') }}</label>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  ref,
  watch,
  computed,
} from '@vue/composition-api'
import dashboardService from '@/services/dashboard.service'
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import { saveAs } from 'file-saver'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BOverlay,
    vSelect,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchDashboardUserElementTabFields,
      createDashboardUserElementTabGetData,
      createDashboardUserElementTabGetExcelFile,
    } = dashboardService()
    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(10)
    const refList = ref(null)
    const currentPage = ref(1)
    const totalRecords = ref(0)
    const sortBy = ref('')
    const isSortDirDesc = ref(true)
    const fields = ref([])
    const showLoad = ref(false)

    const fetchRecords = (ctx, callback) => {
      showLoad.value = true
      const sortString = `${sortBy.value} ${isSortDirDesc.value ? 'ASC' : 'DESC'}`
      createDashboardUserElementTabGetData(props.element.tableroUsuarioElementoId, {
        condiciones: props.element.condicionesFiltrado,
        obtenerTotal: true,
        offset: currentPage.value === 1 ? 0 : (currentPage.value * perPage.value) - perPage.value,
        limit: perPage.value,
        sort: sortBy.value === '' ? props.element.parametrosConsulta.sort : sortString,
      }, tabData => {
        if (tabData) {
          totalRecords.value = tabData.totalRows
          callback(tabData.items)
          showLoad.value = false
        } else {
          showLoad.value = false
        }
      })
    }

    const refresh = () => {
      refList.value.refresh()
    }

    const downloadReport = callback => {
      const sortString = `${sortBy.value} ${isSortDirDesc.value ? 'ASC' : 'DESC'}`
      createDashboardUserElementTabGetExcelFile(props.element.tableroUsuarioElementoId, {
        condiciones: props.element.condicionesFiltrado || [],
        obtenerTotal: true,
        sort: sortBy.value === '' ? '' : sortString,
      }, data => {
        const file = new File([data], `${props.element.nombre}.xlsx`)
        saveAs(file, `${props.element.nombre}.xlsx`)
        callback()
      })
    }

    const refreshFields = () => {
      fetchDashboardUserElementTabFields(props.element.tableroUsuarioElementoId, data => {
        fields.value = data
        fields.value.forEach(field => {
          if (field.sortable && sortBy.value === '') {
            sortBy.value = field.sortKey
          }
        })
      })
    }

    refreshFields()

    watch([perPage, currentPage], () => {
      refresh()
    })
    watch(() => props.element, () => {
      refresh()
      refreshFields()
    })

    const dataMeta = computed(() => {
      const localItemsCount = perPage.value
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalRecords.value,
      }
    })

    return {
      perPageOptions,
      perPage,
      refList,
      currentPage,
      totalRecords,
      sortBy,
      isSortDirDesc,
      fields,
      fetchRecords,
      dataMeta,
      showLoad,

      refreshFields,
      refresh,
      downloadReport,
    }
  },
}
</script>

<style scoped>
.circle {
  border-radius: 20%;
  background-color: white;
  color: #003265;
  margin-left: 0.3rem;
}
.circle :hover{
  cursor: pointer;
}

</style>
