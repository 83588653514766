<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <v-select
          id="select-view"
          v-model="selectedElement"
          placeholder="Elementos disponibles"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :clearable="false"
          label="nombre"
          class="outline-primary"
          :options="dashboard.tabulares"
          @input="conditionFilterOptionsAdd"
        />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-dropdown
          id="dropdown-form"
          ref="refDropdownNewElement"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          left
          variant="outline-primary"
          text="Agregar elemento"
          menu-class="w-100"
          block
        >
          <b-dropdown-form>
            <b-form-group
              label="Elemento"
              label-for="element"
            >
              <v-select
                id="element"
                v-model="selectedElementId"
                :options="elements"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="true"
                label="texto"
                :reduce="option => option.id"
                transition=""
              />
            </b-form-group>
            <b-button
              variant="primary"
              size="sm"
              :disabled="!selectedElementId"
              @click="onAddElement"
            >
              Agregar
            </b-button>
          </b-dropdown-form>
        </b-dropdown>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-card>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <strong>{{ selectedElement.nombre }} - {{ selectedElement.descripcion }}</strong>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <b-button
                :disabled="selectedElement.elementoId == 0"
                variant="outline-primary"
                block
                class="round"
                @click="onOpenFilters(selectedElement)"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="18"
                />
                Filtros
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <b-button
                :disabled="selectedElement.elementoId == 0"
                variant="outline-primary"
                block
                class="round"
                @click="onOpenActions(selectedElement)"
              >
                <feather-icon
                  icon="CommandIcon"
                  size="18"
                />
                Acciones
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <b-button
                :disabled="selectedElement.elementoId == 0"
                variant="outline-primary"
                block
                class="round"
                @click="onOpenElementOptions(selectedElement)"
              >
                <feather-icon
                  icon="SlidersIcon"
                  size="18"
                />
                Opciones
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <b-row>
                <span>Filtros:</span>
                <div
                  v-for="condition in selectedElement.condicionesFiltrado"
                  :key="condition.elementoCondicionFiltradoId"
                >
                  <b-col
                    v-if="resolveFilterVisibility(condition)"
                  >
                    <b-badge
                      variant="primary"
                    >
                      <span
                        :class="`circle ${condition.esObligatoria ? '': 'cursor-pointer'}`"
                        @click="onCleanFilter(condition)"
                      >
                        {{ resolveFilterValue(condition) }}
                        <feather-icon
                          v-if="!condition.esObligatoria"
                          size="30"
                          icon="XIcon"
                        />
                      </span>
                    </b-badge>
                  </b-col>
                </div>
              </b-row>
            </b-col>
          </b-row>
          <b-card-body>
            <!-- Tabular -->
            <user-dahboard-tab-element
              v-if="selectedElement.columnas.length !== 0"
              ref="refElementTab"
              :element="selectedElement"
              :condition-filters.sync="selectedElement.condicionesFiltrado"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!--Modal Element Config-->
    <validation-observer
      #default="{ handleSubmit }"
    >
      <b-modal
        ref="refModalElementConfig"
        no-close-on-backdrop
        modal-class="modal-primary"
        centered
        title="Configuracion de componente"
        size="xl"
      >
        <!--Tabular-->
        <div
          v-if="selectedElement && selectedElement.columnas"
        >
          <validation-provider
            #default="validationContext"
            name="Nombre"
            rules="required"
          >
            <b-form-group
              label="Nombre"
            >
              <b-form-input
                v-model="selectedElement.nombre"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Descripción"
            rules="required"
          >
            <b-form-group
              label="Descripción"
            >
              <b-form-input
                v-model="selectedElement.descripcion"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-row class="mt-1">
            <b-col
              cols="12"
              offset-md="1"
              md="10"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <strong class="mr-2">Filtrar</strong>
              <b-form-input
                v-model="searchColumna"
                placeholder="Nombre de columna"
                debounce="300"
              />
            </b-col>
          </b-row>
          <b-row
            class="mb-1 mt-1"
          >
            <b-col
              cols="12"
              md="4"
            >
              <strong>Columna</strong>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <strong>Etiqueta columna</strong>
            </b-col>
            <b-col
              v-if="searchColumna === ''"
              cols="12"
              md="2"
            >
              <b-form-checkbox
                v-model="modalElementSelectAll"
                @change="onModalElementSelectAll(selectedTabElementColumns)"
              >
                <strong>Mostrar</strong>
              </b-form-checkbox>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <strong
                title="Arrastra y Suelta las filas para seleccionar el orden"
              >
                <feather-icon
                  icon="InfoIcon"
                  size="16"
                  class="align-middle"
                />
              </strong>
            </b-col>
          </b-row>
          <vue-perfect-scrollbar
            v-if="searchColumna == ''"
            :settings="perfectScrollbarSettings"
            class="ps-customizer-area scroll-area"
          >
            <draggable
              v-model="selectedTabElementColumns"
              class="list-group list-group-flush cursor-move"
              tag="ul"
            >
              <transition-group
                type="transition"
                name="flip-list"
              >
                <b-list-group-item
                  v-for="item in selectedTabElementColumns"
                  :key="item.elementoTabularColumnaId"
                  tag="li"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      {{ item.columna }}
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="item.columna"
                        rules="required"
                      >
                        <b-form-group>
                          <b-form-input
                            v-model="item.etiquetaMostrar"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-form-group class="text-center">
                        <b-form-checkbox
                          v-model="item.mostrar"
                          class="text-center"
                          @change="onTabularSelectColumn(item)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="2"
                    >
                      <b-form-group>
                        <feather-icon
                          icon="ChevronsUpIcon"
                          size="16"
                          class="align-middle"
                        />
                        <feather-icon
                          icon="ChevronsDownIcon"
                          size="16"
                          class="align-middle"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-list-group-item>
              </transition-group>
            </draggable>
          </vue-perfect-scrollbar>
          <b-list-group
            v-else
            class="list-group list-group-flush cursor-move"
          >
            <b-list-group-item
              v-for="item in tabularColumnasSearch"
              :key="item.elementoTabularColumnaId"
              tag="li"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  {{ item.columna }}
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('settings.dashboards.elements.addEdit.conditionFilter.columns.label')"
                    rules="required"
                  >
                    <b-form-group>
                      <b-form-input
                        v-model="item.etiquetaMostrar"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.mostrar"
                      @change="onTabularSelectColumn(item)"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-success"
              size="sm"
              class="float-right"
              type="submit"
              @click="handleSubmit(onSaveChangesElement)"
            >
              <feather-icon
                icon="SaveIcon"
                size="18"
              />
              Guardar cambios
            </b-button>
            <b-button
              variant="outline-danger"
              size="sm"
              class="float-left"
              @click="onDeleteElement"
            >
              <feather-icon
                icon="TrashIcon"
                size="18"
              />
              Eliminar elemento
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>
    <!--Modal Filters -->
    <validation-observer
      #default="{ handleSubmit }"
    >
      <b-modal
        ref="refModalFilters"
        no-close-on-backdrop
        modal-class="modal-primary"
        centered
        title="Filtros"
        size="xl"
      >
        <b-row>
          <!--Single filter, static filters are excluded-->
          <b-col
            v-for="filter in selectedElement.condicionesFiltrado"
            :key="filter.elementoCondicionFiltradoId"
            cols="4"
            md="4"
          >
            <div
              v-if="filter.condicionTipoId !== 2 && filter.condicionTipoId !== 7 && filter.condicionTipoId !== 8 && filter.condicionTipoId !== 9"
              cols="4"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                :name="filter.etiqueta"
                :rules="{required: filter.esObligatoria }"
              >
                <b-form-group
                  :label="filter.condicionTipoId === 5 ? `${filter.etiqueta} desde` : filter.etiqueta"
                  :state="validationContext.errors.length > 0 ? false : null"
                >
                  <component
                    :is="resolveComponent(filter)"
                    v-if="filter.condicionTipoId === 5"
                    v-model="filter['fechaPeriodo']['inicio']"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="texto"
                    :options="filter.condicionFiltradoOpciones"
                    :reduce="option => option.valor"
                    :multiple="filter.permitirMultiseleccion"
                    locale="es"
                    :state="getValidationState(validationContext)"
                    reset-button
                    close-button
                  />
                  <component
                    :is="resolveComponent(filter)"
                    v-if="filter.condicionTipoId !== 5"
                    v-model="filter[resolveFilterAttributeName(filter)]"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="texto"
                    :options="filter.condicionFiltradoOpciones"
                    :reduce="option => option.valor"
                    :multiple="filter.permitirMultiseleccion"
                    locale="es"
                    :state="getValidationState(validationContext)"
                    reset-button
                    close-button
                  />
                  <b-form-invalid-feedback :state="validationContext.errors.length > 0 ? false : null">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            <!--Period filter, static filters are excluded-->
            <div
              v-if="filter.condicionTipoId === 5 && filter.condicionTipoId !== 2"
              cols="4"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                :name="filter.etiqueta"
                :rules="filter.esObligatorio ? 'required' : ''"
              >
                <b-form-group
                  :label="`${filter.etiqueta} hasta`"
                  :state="errors.length > 0 ? false:null"
                >
                  <component
                    :is="resolveComponent(filter)"
                    v-model="filter['fechaPeriodo']['fin']"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :placeholder="filter.etiqueta"
                    reset-button
                    locale="es"
                  />
                </b-form-group>
              </validation-provider>
            </div>
          </b-col>
        </b-row>
        <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-danger"
              size="sm"
              block
              @click="handleSubmit(onFilter)"
            >
              <feather-icon
                icon="FilterIcon"
                size="18"
              />
              Filtrar
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>

    <!--Modal Actions -->
    <b-modal
      ref="refModalActions"
      no-close-on-backdrop
      modal-class="modal-primary"
      centered
      title="Acciones"
    >
      <b-button
        v-if="selectedElement"
        :disabled="downloadingReport"
        variant="outline-success"
        size="sm"
        block
        @click="onDownloadReportAction"
      >
        <feather-icon
          icon="DownloadIcon"
          size="18"
        />
        Descargar reporte
        <b-spinner
          v-if="downloadingReport"
          size="sm"
        />
      </b-button>
      <template #modal-footer>
        <div class="w-100" />
      </template>
    </b-modal>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import dashboardService from '@/services/dashboard.service'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BCardBody,
  BFormGroup,
  BDropdown,
  BDropdownForm,
  BListGroupItem,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormDatepicker,
  BListGroup,
  BModal,
  BBadge,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import generalDataService from '@/services/generalData.service'
import Vue from 'vue'
import UserDahboardTabElement from './UserDashboardTabElement.vue'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardBody,
    BFormGroup,
    BDropdown,
    BDropdownForm,
    BListGroupItem,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormDatepicker,
    BListGroup,
    BModal,
    BBadge,
    BSpinner,
    draggable,
    vSelect,
    VuePerfectScrollbar,

    ValidationProvider,
    ValidationObserver,
    UserDahboardTabElement,
  },
  setup() {
    const refElements = ref(null)
    const refDropdownNewElement = ref(null)
    const refModalElementConfig = ref(null)
    const refModalFilters = ref(null)
    const refModalActions = ref(null)

    const {
      fetchNewDashboard,
      fetchAvalibleElements,
      createDashboardUserElement,
      updateDashboardUserElement,
      removeDashboardUserElement,
      updateDhashboardUserElementTabColumns,
    } = dashboardService()

    const {
      fetchConditionFilterOptions,
      fetchConditionFilterAuxiliar,
      fetchIsFromSource,
      fetchIsPermanent,
    } = generalDataService()

    const blankElement = {
      nombre: '',
      archivado: false,
      descripcion: '',
      elementoId: 0,
      elementoTabularId: 0,
      columnas: [],
      condicionesFiltrado: [],
      parametrosConsulta: {
        limit: 10,
        offset: 0,
        sort: '',
      },
      tableroUsuarioElementoId: 0,
      tableroUsuarioId: 0,
    }

    const selectedElement = ref(JSON.parse(JSON.stringify(blankElement)))
    const searchColumna = ref('')
    const tabularColumnasSearch = ref([])
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const dashboard = ref({
      tableroUsuarioElementos: [],
    })
    const elements = ref([])
    fetchAvalibleElements(data => {
      elements.value = data
    })

    const selectedElementId = ref(null)
    const selectedTabElementColumns = ref([])
    const refElementTab = ref(null)

    const downloadingReport = ref(false)

    const inicializeDateTimeFilters = element => {
      const date = new Date()
      const startDate = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000)

      element.condicionesFiltrado.forEach(condicion => {
        const changeCondition = condicion
        if (changeCondition.condicionTipoId === 5) {
          /* Vue.set beacuse we need to active reactivity in depth */
          Vue.set(changeCondition, 'fechaPeriodo', {
            inicio: `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`,
            fin: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getDate()}`,
          })
        }

        if (changeCondition.condicionTipoId === 1 && (changeCondition.columnaTipoDatoId === 2 || changeCondition.columnaTipoDatoId === 3)) {
          changeCondition.valorFechaHora = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
        }
      })
    }

    const refresh = () => {
      fetchNewDashboard({}, data => {
        dashboard.value = data
        dashboard.value.tabulares.forEach(element => {
          /**
           * Inicialize datetime / date filters
           */
          inicializeDateTimeFilters(element)
          /**
           * Asign tablero usuario elemento to column
           */
          element.columnas.forEach(column => {
            // eslint-disable-next-line no-param-reassign
            column.tableroUsuarioElementoId = element.tableroUsuarioElementoId
          })
        })
      })
    }

    refresh()

    /* Elements manangment */
    const onAddElement = () => {
      createDashboardUserElement({ elementoId: selectedElementId.value }, () => {
        refresh()
        refDropdownNewElement.value.hide(true)
      })
    }

    const onOpenElementOptions = element => {
      // Tabular
      selectedTabElementColumns.value = element.columnas
      selectedTabElementColumns.value.sort((a, b) => a.orden - b.orden)
      refModalElementConfig.value.show()
    }

    const onDeleteElement = () => {
      removeDashboardUserElement(selectedElement.value.tableroUsuarioElementoId, () => {
        selectedElement.value = JSON.parse(JSON.stringify(blankElement))
        refresh()
        refModalElementConfig.value.hide()
        selectedTabElementColumns.value = []
      })
    }

    const onSaveChangesElement = () => {
      updateDashboardUserElement(selectedElement.value.tableroUsuarioElementoId, selectedElement.value, () => {
        // Tabular
        updateDhashboardUserElementTabColumns(selectedElement.value.tableroUsuarioElementoId, selectedTabElementColumns.value, () => {
          refresh()
          refModalElementConfig.value.hide()
          refElementTab.value.refreshFields()
          refElementTab.value.refresh()

          selectedTabElementColumns.value = []
        })
      })
    }

    /* Filters */
    const resolveComponent = filter => {
      let formControl = ''

      switch (filter.condicionTipoId) {
        /* 1 Automatico */
        case 1:
          switch (filter.columnaTipoDatoId) {
            /**
             * 1 Cadena SQL Server
             * 4 Decimal SQL Server
             * 5 Entero SQL Server
             * 7 Texto
             */
            case 1:
            case 4:
            case 5:
            case 7:
              formControl = 'b-form-input'
              break
            /**
             * 2 Fecha/Hora SQL Server
             * 3 Fecha SQL Server
             */
            case 2:
            case 3:
              formControl = 'b-form-datepicker'
              break
            /**
             * 6 Logico
             */
            case 6:
              formControl = 'b-form-checkbox'
              break
            default:
              formControl = ''
          }
          break
        /**
         * 3 Valores predefinidos
         * 4 Valores predefinidos desde una fuente de datos
         */
        case 3:
        case 4:
          formControl = 'v-select'
          break
        /**
         * 5 Periodo
         */
        case 5:
          formControl = 'b-form-datepicker'
          break
        /**
         * 2 Condición fija
         * 6 Sesión de Usuario
         */
        case 2:
        case 6:
        default:
          formControl = ''
      }
      return formControl
    }

    const resolveFilterAttributeName = filter => {
      /*
        When condicion type period
        * 5 Periodo desde una fecha de inicio hasta uan fecha de fin.
       */
      if (filter.condicionTipoId === 5) return 'fechaPeriodo'

      /**
       * All the others condition type
       */
      let attribute = ''
      const isPlural = filter.permitirMultiseleccion

      switch (filter.columnaTipoDatoId) {
        /**
         * 1 Cadena SQL Server
         * 7 Texto SQL Server
         */
        case 1:
        case 7:
          attribute = `valor${isPlural ? 'es' : ''}Cadena`
          break
        /**
         * 2 Fecha/Hora SQL Server
         * 3Fecha SQL Server
         */
        case 2:
          attribute = `valor${isPlural ? 'es' : ''}FechaHora`
          break
        case 3:
          attribute = `valor${isPlural ? 'es' : ''}Fecha`
          break
        /**
         * 4 Decimal SQL Server
         */
        case 4:
          attribute = `valor${isPlural ? 'es' : ''}Decimal`
          break
        /**
         * 5 Entero SQL Server
         */
        case 5:
          attribute = `valor${isPlural ? 'es' : ''}Entero`
          break
        /**
         * Lógico SQL Server
         */
        case 6:
          attribute = `valor${isPlural ? 'es' : ''}Logico`
          break
        default:
          attribute = `valor${isPlural ? 'es' : ''}Cadena`
          break
      }

      return attribute
    }

    const resolveFilterValue = filter => {
      let filterValue = ''

      switch (filter.condicionTipoId) {
        /* 1 Automatico */
        case 1:
          filterValue = `${filter.etiqueta}: ${filter[resolveFilterAttributeName(filter)]}`
          break
        /**
         * 3 Valores predefinidos
         * 4 Valores predefinidos desde una fuente de datos
         */
        case 3:
        case 4:
          if (filter.permitirMultiseleccion) {
            const opciones = filter.condicionFiltradoOpciones.filter(s => filter[resolveFilterAttributeName(filter)].includes(s.valor))
            filterValue = `${filter.etiqueta}: ${opciones.map(m => m.texto).toString()}`
          } else {
            const opcion = filter.condicionFiltradoOpciones.filter(f => f.valor === filter[resolveFilterAttributeName(filter)])
            filterValue = `${filter.etiqueta}: ${opcion[0].texto}`
          }
          break
        /**
         * 5 Periodo
         */
        case 5:
          filterValue = `${filter.etiqueta} desde ${filter.fechaPeriodo.inicio.substring(0, 10)} hasta ${filter.fechaPeriodo.fin.substring(0, 10)}`
          break
        /**
         * 2 Condición fija
         * 6 Sesión de Usuario
         */
        case 2:
        case 6:
        default:
          filterValue = ''
      }
      return filterValue
    }

    const resolveFilterVisibility = filter => {
      const filterAttibute = resolveFilterAttributeName(filter)

      if (filter[filterAttibute] !== null
      && filter[filterAttibute] !== ''
      && filter[filterAttibute] !== undefined
      && filter[filterAttibute].length !== 0
      && (filter.condicionTipoId === 5 && filter.fechaPeriodo.inicio !== null && filter.fechaPeriodo.fin !== null)) {
        return true
      }
      return false
    }

    const onOpenFilters = () => {
      refModalFilters.value.show()
    }

    const onFilter = () => {
      refModalFilters.value.hide()
      refElementTab.value.refresh()
    }

    const onCleanFilter = filter => {
      const attributeName = resolveFilterAttributeName(filter)
      const changeFilter = filter

      if (attributeName === 'fechaPeriodo') {
        changeFilter[attributeName].inicio = null
        changeFilter[attributeName].fin = null
      } else {
        changeFilter[attributeName] = null
      }

      refElementTab.value.refresh()
    }

    const onOpenActions = () => {
      refModalActions.value.show()
    }

    const onDownloadReportAction = () => {
      downloadingReport.value = true
      refElementTab.value.downloadReport(() => {
        refModalActions.value.hide()
        downloadingReport.value = false
      })
    }
    // Validation
    const {
      getValidationState,
    } = formValidation()

    const modalElementSelectAll = ref(false)

    const onModalElementSelectAll = columns => {
      if (searchColumna.value === '') {
        columns.forEach(column => {
          const asigColumn = column
          asigColumn.mostrar = modalElementSelectAll.value
        })
      }
    }

    const onTabularSelectColumn = item => {
      // eslint-disable-next-line no-param-reassign
      item.orden = item.mostrar ? -1 : item.orden
      selectedTabElementColumns.value.sort((a, b) => a.orden - b.orden)
    }

    watch(searchColumna, () => {
      if (searchColumna.value !== '') {
        tabularColumnasSearch.value = selectedTabElementColumns.value.filter(columna => (columna.columna.toUpperCase().includes(searchColumna.value.toUpperCase()) || columna.etiquetaMostrar.toUpperCase().includes(searchColumna.value.toUpperCase())))
      }
    })

    /** Pendiente de revisar */
    const pagination = ref(
      {
        skip: 0,
        take: 0,
      },
    )

    const conditionFilterOptionsAdd = () => {
      selectedElement.value.condicionesFiltrado.forEach(condition => {
        const item = condition
        fetchIsFromSource(item.condicionTipoId, isFromSource => {
          if (isFromSource) {
            fetchConditionFilterAuxiliar(item.condicionFiltradoId, pagination.value, data => {
              item.condicionFiltradoOpciones = data.opciones
              // item.condicionFiltradoOpciones = data.opciones.map(m => ({
              //   texto: m.texto,
              //   valor: item.columnaTipoDatoId === 5 ? Number(m.valor) : m.valor,
              //   orden: m.orden,
              //   preseleccionado: m.preseleccionado,
              //   archivado: m.archivado,
              // }))
            })
          } else {
            fetchIsPermanent(item.condicionTipoId, isPermanent => {
              if (isPermanent) {
                fetchConditionFilterOptions({ condicionFiltradoId: item.condicionFiltradoId }, data => {
                  item.condicionFiltradoOpciones = data.condicionFiltradoOpciones
                  if (item.esObligatoria) {
                    item.value = item.condicionFiltradoOpciones[0].valor
                  }
                })
              }
              if (item.incluirOpcionTodos) {
                item.condicionFiltradoOpciones.push({
                  texto: 'TODO',
                  valor: '[TODO]',
                })
              }
            })
          }
        })
      })
    }

    return {
      refElements,
      refDropdownNewElement,
      refModalElementConfig,
      refModalFilters,
      refModalActions,
      dashboard,
      elements,
      selectedElementId,
      selectedTabElementColumns,
      selectedElement,
      refElementTab,
      searchColumna,
      tabularColumnasSearch,
      perfectScrollbarSettings,

      downloadingReport,

      resolveComponent,
      resolveFilterAttributeName,
      resolveFilterValue,
      resolveFilterVisibility,

      onAddElement,
      onOpenElementOptions,
      onDeleteElement,
      onSaveChangesElement,

      onOpenFilters,
      onFilter,
      onCleanFilter,
      onOpenActions,
      onDownloadReportAction,

      modalElementSelectAll,
      onModalElementSelectAll,
      onTabularSelectColumn,
      conditionFilterOptionsAdd,
      // validation
      getValidationState,
      required,
    }
  },
}
</script>
<style scoped>
.ps-customizer-area {
  height: 50vh
}
</style>
